<template>
  <v-data-table
    :headers="headers"
    :items="sessions"
    :sort-by="['clientName', 'dateOfService']"
    :group-by="groupBy"
    :search="search"
    v-model="selected"
    item-key="sessionId"
    show-select
    fixed-header
    hide-default-footer
    disable-pagination
    dense
    class="details"
  >
    <template v-slot:[`top`]>
      <v-row>
        <invoice-modal
          :sessionIds="selected.map((i) => i.sessionId)"
        ></invoice-modal>
      </v-row>
      <v-row>
        <v-radio-group v-model="groupBy" row label="Group charges by">
          <v-radio label="Program" value="programName"></v-radio>
          <v-radio label="Service" value="serviceName"></v-radio>
          <v-radio label="Client" value="clientName"></v-radio>
        </v-radio-group>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          clearable
          label="Search"
          single-line
          hide-details
        ></v-text-field> </v-row
    ></template>
    <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen }">
      <td :colspan="headers.length">
        <v-btn @click="toggle" x-small icon :ref="group">
          <v-icon v-if="isOpen">mdi-minus</v-icon>
          <v-icon v-else>mdi-plus</v-icon>
        </v-btn>
        <span class="mx-5 font-weight-bold" style="font-size: 14px">{{
          group
        }}</span>
      </td>
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <payments-modal :sessionId="item.sessionId"></payments-modal>
    </template>
  </v-data-table>
</template>
<script>
import InvoiceModal from "../Billing/invoice-modal.vue";
import PaymentsModal from "./payments-modal.vue";
export default {
  props: ["sessions"],
  components: {
    InvoiceModal,
    PaymentsModal,
  },
  data() {
    return {
      headers: [
        { text: "Session ID", value: "sessionId" },
        { text: "Provider", value: "providerName" },
        { text: "Client", value: "clientName" },
        { text: "Client OSIS", value: "clientOsis", sortable: false },
        {
          text: "Client DOB",
          value: "formattedClientDob",
          sortable: false,
        },
        { text: "District", value: "district" },
        { text: "Date", value: "formattedDos" },
        { text: "Time", value: "startTime", sortable: false },
        { text: "Duration", value: "formattedDuration", sortable: false },
        { text: "Program", value: "programName" },
        { text: "Service", value: "serviceName" },
        { text: "Location", value: "location" },
        { text: "Bilingual", value: "bilingual", sortable: false },
        { text: "Language", value: "language", sortable: false },
        {
          text: "Invoice Date",
          value: "formattedInvoiceDate",
          sortable: false,
        },
        { text: "Billed Amount", value: "formattedBilledAmount" },
        { text: "Balance", value: "formattedBalance", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
      groupBy: "programName",
      search: "",
      selected: [],
    };
  },
};
</script>
<style>
.details td {
  font-size: 11px !important;
}
</style>
